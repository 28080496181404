import { Orders, Utils, Users, Studio } from '@hellohair/types';

type RequestListRow = {
  orderId: string;
  orderDate: Date;
  name: string;
  doctor: string;
  product: string;
  requestState: string;
  isFollowup: boolean;
};

export const NEW_CLIENT_BONUS = '4.75'; // TODO: get from SETTINGS
export const DOCTOR_FEE_1 = '4.66'; // TODO: get from SETTINGS
export const DOCTOR_FEE_2 = '1.75'; // TODO: get from SETTINGS

export const getFirstDateOfMonthsAgo = (monthsAgo: number) => {
  const d = new Date();
  const dateInPast = d.setMonth(d.getMonth() - monthsAgo);
  const yearInPast = new Date(dateInPast).getFullYear();
  const monthInPast = new Date(dateInPast).getMonth();

  return new Date(yearInPast, monthInPast, 1).getTime();
};

export const getAllDoctorsFromStudio = (users: Users.BackendUser[], selectedStudio: Studio | null) => {
  return users.filter((user) => {
    const isDoctor = user.groups.includes('doctor');
    const isManager = user.sub === selectedStudio?.managerId;
    const belongsToStudio = user.studio === selectedStudio?.id;
    return isDoctor && (isManager || belongsToStudio);
  });
};

export const getRequestsStats = (requests: Utils.DBItem<Orders.OrderRequest>[]) => {
  return requests.reduce(
    (a, c) => {
      switch (c.orderState) {
        case Orders.OrderRequestState.IDLE:
          a.open++;
          break;
        case Orders.OrderRequestState.ALLOWED:
          a.allowed++;
          break;
        case Orders.OrderRequestState.DENIED:
          a.denied++;
          break;
      }
      return a;
    },
    {
      open: 0,
      allowed: 0,
      denied: 0,
    }
  );
};

export const groupByCurrentAndPreviousMonth = (objects: Utils.DBItem<Orders.OrderRequest>[]) => {
  const now = new Date();

  // Get the start of the current month
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();

  // Get the start of the previous month
  const startOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1).getTime();

  // Get the end of the previous month (start of the current month)
  const endOfPreviousMonth = startOfCurrentMonth;

  // Initialize the result object
  const result: {
    currentMonth: Utils.DBItem<Orders.OrderRequest>[];
    previousMonth: Utils.DBItem<Orders.OrderRequest>[];
  } = {
    currentMonth: [],
    previousMonth: [],
  };

  // Populate the result with objects grouped by month
  objects.forEach((obj) => {
    const createdAt = obj.createdAt;

    if (createdAt >= startOfCurrentMonth) {
      result.currentMonth.push(obj); // Belongs to the current month
    } else if (createdAt >= startOfPreviousMonth && createdAt < endOfPreviousMonth) {
      result.previousMonth.push(obj); // Belongs to the previous month
    }
  });

  return result;
};

export const groupByDayInMonthWithEmpty = (objects: Utils.DBItem<Orders.OrderRequest>[]) => {
  // Initialize the result with all days as keys and empty arrays
  const result = Array.from({ length: 31 }, (_, i) => i + 1).reduce(
    (acc, day) => {
      acc[day] = [];
      return acc;
    },
    {} as Record<number, Utils.DBItem<Orders.OrderRequest>[]>
  );

  // Populate the result with objects grouped by day
  objects.forEach((obj) => {
    const date = new Date(obj.createdAt);
    const day = date.getDate();
    result[day].push(obj);
  });

  return result;
};

export const calculateOrderEarning = (request: Utils.DBItem<Orders.OrderRequest>) => {
  const factor = parseFloat(request.assignedDoctor?.factor || '1');
  const factor2 = parseFloat(request.assignedDoctor?.factor2 || '1');
  const fee = parseFloat(request.assignedDoctor?.fee || '0');
  const fee2 = parseFloat(request.assignedDoctor?.fee2 || '0');
  return factor * fee + factor2 * fee2;
};

export const calculateTotalEarnings = (reqs: Utils.DBItem<Orders.OrderRequest>[]) => {
  return reqs.reduce((acc, request) => {
    if (request.orderState !== Orders.OrderRequestState.ALLOWED) return acc;
    acc += calculateOrderEarning(request);
    return acc;
  }, 0);
};

export const getRequestTableRow = (request: Utils.DBItem<Orders.OrderRequest>, users: Users.BackendUser[]) => {
  const assignedDoctor = users.find((u) => u.sub === request.assignedDoctor?.doctorId);
  const doctor = assignedDoctor ? assignedDoctor.firstName + ' ' + assignedDoctor.lastName : '';

  const row: RequestListRow = {
    orderId: request.requestId,
    orderDate: new Date(request.createdAt),
    name: request.customer?.customerName,
    doctor,
    product: request.product?.productName ?? 'FOLLOWUP',
    requestState: request.orderState,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    isFollowup: (request as any).isFollowup ?? false,
  };

  return row;
};

// Get the current month in YYYY-MM format
export const getCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Add leading zero
  return `${year}-${month}`;
};
