export enum ROUTE {
  DASHBOARD = '/',
  LOGIN = '/login',
  SET_PASSWORD = '/set-password',
  FORGOT_PASSWORD = '/forgot-password',
  FORMS = '/forms',
  FORM = '/form',
  SETTINGS = '/settings',
  CUSTOMERS = '/customers',
  CONSULTATIONS = '/consultations',
  USERS = '/users',
  STUDIOS = '/studios',
  REQUEST = '/request',
  REQUESTS = '/requests',
  PATIENTS = '/patients',
  DEMOS = '/demos',
  PROFILE = '/profile',
  BILLING = '/billing',
  // IMPRINT = '/imprint',
  // PRIVACY = '/privacy',
  EXPORT_LOGS = '/export-logs',
}
